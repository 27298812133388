import type { HomePageProps } from 'types/oneflare.com.au/home';
import dynamic from 'next/dynamic';
import { useContext, useMemo } from 'react';
import { useThemeContext } from 'shared/components/HighOrderComponent/withThemeProvider/withThemeProvider';
import { publicRuntimeConfig } from 'lib/utils/Environment';
import { NOTIFICATION_STATUS } from 'shared/components/NotificationBanner/utils';
import { CTA_TYPES } from 'shared/components/PageHeader/Assets';
import { useBreakpoint, useWindowResize } from 'shared/utils/hooks';
import { JobFormControllerContext } from 'lib/oneflare-job-form/utils/context';
import PageHeader from 'shared/components/PageHeader/PageHeader';
import HeadContent from 'shared/components/_oneflare/HeadContent/HeadContent';
import NotificationBanner from 'shared/components/NotificationBanner';
import LazyLoadedSection from 'shared/components/LazyLoadedSection';
import HomepageHero from './components/HomepageHero/HomepageHero';
import WhatsLive from './components/WhatsLive';
import BottomBanner from 'shared/components/BottomBanner';
import { NotificationBannerMsgStyled } from 'shared/components/NotificationBanner/styled/NotificationBannerStyled';
import { homePageHowItWorksBusiness, homePageHowItWorksCustomer } from './utils/constants';

// #region dynamic components
export const Reviews = dynamic(() => import('shared/components/ReviewSection'));
export const HowItWorks = dynamic(() => import('shared/components/HowItWorks/HowItWorks'));
export const MobileLeads = dynamic(() => import('./components/MobileLeads/MobileLeads'));
export const PopularCategories = dynamic(() => import('shared/components/PopularCategories/PopularCategories'));
export const CostGuidesHome = dynamic(() => import('./CostGuidesHome'));
export const FooterCta = dynamic(() => import('./components/FooterCta/FooterCta'));
export const Footer = dynamic(() => import('shared/components/Footer'));
// #endregion dynamic components

const CACHE_KEY_PREFIX = process.env.CACHE_KEY_PREFIX;

const defaultProps = {
  currentUser: null,
  params: {
    randomNumber: 1,
    invitation: ''
  },
  popularCategoriesData: {
    cities: [],
    ipDetectedCity: null
  },
  whatsLiveData: []
} satisfies HomePageProps;

const bottomHead = (
  <>
    <link rel="stylesheet preload" href="https://cdn.oneflare.com/static/theme/2018/fonts/sf/sf-ui-display-thin.css" as="style" />
    <meta name="google" content="nositelinkssearchbox" />
  </>
);

const pageTracking = {
  environment: publicRuntimeConfig.ENVIRONMENT,
  deployment_version: CACHE_KEY_PREFIX,
  form_type: 'job_request',
  form_version: '3',
  form_step: '01'
};

const OneflareHome = (props: HomePageProps) => {
  const {
    params = {
      staticRender: false
    },
    popularCategoriesData,
    whatsLiveData = [],
    currentUser: user
  } = props satisfies HomePageProps;
  const currentUser = useMemo(() => user, [user]);
  const { controller } = useContext(JobFormControllerContext);
  const { staticRender } = params;
  const theme = useThemeContext();
  const isDesktop = useBreakpoint('lg');
  const smBreakPoint = (theme as any).breakpoint.sm.min.split('px')[0];
  const IPHONE_X_WIDTH = smBreakPoint - 201;
  const viewportWidth = useWindowResize(0);
  return (
    <>
      <HeadContent
        pageUrl={publicRuntimeConfig.SITE_URL}
        pageTitle="Oneflare: Connecting Australians With Experts &amp; Businesses"
        bottomHead={bottomHead}
        tracking={pageTracking}
      />
      {(params.invitation === 'expired' || params.invitation === 'revoked') && (
        <NotificationBanner status={NOTIFICATION_STATUS.ERROR}>
          <NotificationBannerMsgStyled>
            {`Your invitation to join has ${params.invitation === 'expired' ? 'expired' : 'been revoked'}, `}
            contact the business owner to arrange another invite.
          </NotificationBannerMsgStyled>
        </NotificationBanner>
      )}
      <PageHeader
        kind="overlay"
        ctaKind={CTA_TYPES.POST_JOB}
        openInAppStickyHeader
        mobileCta={{
          label: viewportWidth >= IPHONE_X_WIDTH ? 'Open in app' : 'Open app',
          link: '/oneflare-app?utm_campaign=header'
        }}
      />
      <HomepageHero randomNumber={params.randomNumber} />
      <LazyLoadedSection style={{ minHeight: '228px' }} autoLoad={staticRender} rootMargin="150px">
        <HowItWorks
          ctaButton={null}
          kind="threeColumn"
          panelData={homePageHowItWorksCustomer}
          mainTitle="How Oneflare works"
          mainTitleMobile="Need a job done?"
          mainDescription="Oneflare can connect you to the right business, first time and every time."
          mainDescriptionMobile="Oneflare can connect you to the right experts, first time and every time. Here’s how it works:"
          buttonLabel="Post a job"
          handleButtonClick={() => {
            controller?.open();
          }}
        />
        <HowItWorks
          ctaButton={null}
          kind="threeColumn"
          panelData={homePageHowItWorksBusiness}
          mainTitle="Oneflare for Business"
          mainTitleMobile="Oneflare for Business"
          mainDescription="Start meeting customers on Oneflare. Browse leads and choose the customers you want to connect with."
          mainDescriptionMobile="Start meeting customers on Oneflare. Browse leads and choose the customers you want to connect with."
          buttonLabel="Register business"
          handleButtonClick={() => {
            window.location.href = '/businesses/new';
          }}
        />
      </LazyLoadedSection>
      <LazyLoadedSection style={{ minHeight: '228px' }} autoLoad={staticRender} rootMargin="150px">
        <MobileLeads />
      </LazyLoadedSection>
      <PopularCategories
        isBot={staticRender}
        popularCategoriesData={popularCategoriesData}
      />
      <WhatsLive whatsLiveData={whatsLiveData} />
      <Reviews
        summary="Every day, thousands of Australians come to Oneflare to make connections with the right experts"
      />
      <CostGuidesHome
        heading="Get the right price for your job"
        subHeading="The Oneflare Cost Guide Centre is your one-stop
      shop to help you set your budget;
      from smaller tasks to larger projects."
        showSeeMoreButton
        className=""
        showCostsHomeButton={false}
        isBot={staticRender}
      />
      <LazyLoadedSection style={{ minHeight: '288px' }} autoLoad={staticRender} rootMargin="150px">
        <FooterCta />
      </LazyLoadedSection>
      <LazyLoadedSection style={{ minHeight: '381px' }} autoLoad={staticRender} rootMargin="150px">
        <Footer />
      </LazyLoadedSection>
      {currentUser?.user?.isBusiness === false && !isDesktop && (<BottomBanner />)}
    </>
  );
};

OneflareHome.defaultProps = defaultProps;

export default OneflareHome;
