// #region types
import type { NextPage } from 'next';
import type { HomePageProps } from 'types/oneflare.com.au/home';
// #endregion types
import withCurrentUser from 'shared/components/HighOrderComponent/withCurrentUser';
import homePageServerProps from '@oneflare-server-side-props/_homePage';
import OneflareHome from 'pages/oneflare.com.au/Home';

const Home: NextPage = (props: HomePageProps) => {
  return <OneflareHome {...props} />;
};

export const getServerSideProps = homePageServerProps;

export default withCurrentUser(Home, {
  rollbarString: 'Oneflare | Homepage Notification Banner | GET_USER query',
  fetchPolicy: 'no-cache'
});
