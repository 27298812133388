import PropTypes from 'prop-types';
import { Wrapper } from './styled/AsyncSVG';
import dynamic from 'next/dynamic';

const InlineSVG = dynamic(() => import('react-inlinesvg'),{
  ssr: false
});

const propTypes = {
  height: PropTypes.number,
  path: PropTypes.string.isRequired,
  primaryFill: PropTypes.string,
  secondaryFill: PropTypes.string,
  width: PropTypes.number,
  className: PropTypes.string
};

const defaultProps = {
  height: null,
  primaryFill: null,
  secondaryFill: null,
  width: null,
  className: null
};

const AsyncSVG = (props) => {
  const {
    path,
    className,
    secondaryFill,
    primaryFill,
    width,
    height,
    ...rest
  } = props;
  return (
    <Wrapper
      className={className}
      $secondaryFill={secondaryFill}
      $primaryFill={primaryFill}
      $width={width}
      $height={height}
      {...rest}
    >
      <InlineSVG
        cacheRequests
        src={path}
        preloader={null}
      />
    </Wrapper>
  );
};

AsyncSVG.propTypes = propTypes;
AsyncSVG.defaultProps = defaultProps;

export default AsyncSVG;
