import { useState, useLayoutEffect } from 'react';
import {
  BottomBannerStyled, BottomBannerTextStyled, ButtonLinkStyled
} from './styled/BottomBannerStyled';

const BottomBanner = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useLayoutEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <BottomBannerStyled show={scrollPosition === 0}>
      <BottomBannerTextStyled>
        Download the Oneflare app for the best experience
      </BottomBannerTextStyled>
      <ButtonLinkStyled
        kind="primary-sm"
        link="/oneflare-app?utm_campaign=header"
        target="_blank"
      >
        Get the app
      </ButtonLinkStyled>
    </BottomBannerStyled>
  );
};

export default BottomBanner;
