import { styled } from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  ${({ $height }) => $height && `height: ${$height}px;`}

  ${({ $primaryFill }) => $primaryFill && `.primaryFill {
    fill: ${$primaryFill};
  }`}

  ${({ $secondaryFill }) => $secondaryFill && `.secondaryFill {
    fill: ${$secondaryFill};
  }`}

  ${({ $width }) => $width && `width: ${$width}px;`}
`;
